<template>
  <div>
    <v-card>
      <v-card-title> Relatório de Eventos LPR</v-card-title>
      <!-- FORM -->
      <v-card-text>
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col>
                <v-autocomplete
                  :items="this.$store.getters.getCamerasSelect"
                  label="Câmera"
                  v-model="relatorio.cameraId"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  label="Data Inicial"
                  v-model="relatorio.start"
                  type="datetime-local"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Data Final"
                  v-model="relatorio.end"
                  type="datetime-local"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="auto">
                <v-checkbox
                  v-model="relatorio.withImage"
                  label="Somente com Imagens"
                ></v-checkbox>
              </v-col>
              <v-col cols="auto">
                <v-checkbox
                  v-model="relatorio.withTrat"
                  label="Somente com Tratamento"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-btn
                color="success"
                text
                @click="loadPDF"
                v-show="$store.getters.getReport.length"
              >
                Gerar PDF
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="load"> Buscar </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- TABELA -->
    <v-card class="mt-2" outlined>
      <v-card outlined>
        <v-card-subtitle>Filtros do relatório</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col>
              <v-autocomplete
                :items="plates"
                label="Placas"
                hint="Selecione as placas"
                persistent-hint
                multiple
                chips
                deletable-chips
                small-chips
                outlined
                clearable
                v-model="filter.plates"
                @change="filterReport"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                :items="brands"
                label="Marcas"
                hint="Selecione as marcas"
                persistent-hint
                multiple
                chips
                deletable-chips
                small-chips
                outlined
                clearable
                v-model="filter.brands"
                @change="filterReport"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                :items="sizes"
                label="Tamanhos"
                hint="Selecione os tamanhos"
                persistent-hint
                multiple
                chips
                deletable-chips
                small-chips
                outlined
                clearable
                v-model="filter.sizes"
                @change="filterReport"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                :items="colors"
                label="Cores"
                hint="Selecione as cores"
                persistent-hint
                multiple
                chips
                deletable-chips
                small-chips
                outlined
                clearable
                v-model="filter.colors"
                @change="filterReport"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                label="Velocidade"
                v-model.number="filter.speed"
                type="number"
                outlined
                min="0"
                @input="filterReport"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn color="warning" text @click="clearFilter">
                Limpar Filtros
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-simple-table dark>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Placa</th>
              <th class="text-left">Velocidade</th>
              <th class="text-left">Evento</th>
              <th class="text-left">Data Evento</th>
              <th class="text-left">Metadados</th>
              <th class="text-left">Imagem</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in report" :key="index">
              <td>{{ item.id }}</td>
              <td>
                <div v-for="(d, idx) in item.detections" :key="idx">
                  <div class="d-flex justify-space-between">
                    <span>{{ d.plate }}</span>
                  </div>
                </div>
              </td>
              <td>
                <div v-for="(d, idx) in item.detections" :key="idx">
                  <div class="d-flex justify-space-between">
                    <span>{{ d.speed }} Km/h</span>
                  </div>
                </div>
              </td>
              <td>{{ item.evtName || `Não Cadastrado ${item.code}` }}</td>
              <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
              <td>
                <div v-for="(d, idx) in item.detections" :key="idx">
                  <div class="d-flex justify-space-between">
                    <span class="font-weight-bold">Marca:</span>
                    <span>{{ d.VehicleSign }}</span>
                  </div>
                  <div class="d-flex justify-space-between">
                    <span class="font-weight-bold">Tamanho:</span>
                    <span>{{ d.VehicleSize }}</span>
                  </div>
                  <div class="d-flex justify-space-between">
                    <span class="font-weight-bold">Cor:</span>
                    <span>{{ d.VehicleColor }}</span>
                  </div>
                  <div class="d-flex justify-space-between">
                    <span class="font-weight-bold">Cor Placa:</span>
                    <span>{{ d.PlateColor }}</span>
                  </div>
                </div>
              </td>
              <td>
                <v-img
                  @click="showImage(item)"
                  max-height="150"
                  max-width="250"
                  :src="item.snapshot + $store.getters.getImgTokenQuery"
                ></v-img>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark dense color="primary">
        <v-toolbar-title>Imagem do evento com as detecções</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card>
        <Atendimento
          @next="nextEvent($event)"
          @previous="previousEvent($event)"
        />
      </v-card>
    </v-dialog>
    <DialogLoading
      v-model="$store.getters.getShowDialog"
      :title="$store.getters.getTitleDialog"
      :message="$store.getters.getMessageDialog"
      :progress="$store.getters.getValueDialog"
    />
  </div>
</template>

<script>
import Atendimento from "../components/Eventos/Atendimento.vue";
import DialogLoading from "../components/Dialogs/DialogLoading.vue";
import PDF from "@/Utils/PDF";
export default {
  name: "RelatorioEventos",
  components: {
    Atendimento,
    DialogLoading,
  },
  data: () => {
    return {
      dialog: false,
      plates: [],
      colors: [],
      brands: [],
      sizes: [],
      relatorio: {
        cameraId: "",
        start: `${new Date().toISOString().substring(0, 10) + "T00:00"}`,
        end: `${new Date().toISOString().substring(0, 10) + "T23:59"}`,
        orderBy: "DESC",
        withTrat: false,
        withImage: false,
      },
      filter: {
        plates: [],
        brands: [],
        sizes: [],
        colors: [],
        speed: 0,
      },
      report: [],
    };
  },
  methods: {
    get() {
      this.$store.dispatch("loadCameras");
    },
    clearFilter() {
      this.filter = {
        plates: [],
        brands: [],
        sizes: [],
        colors: [],
        speed: 0,
      };
      this.filterReport();
    },
    load() {
      this.relatorio.callback = (resp) => {
        console.log("callback", resp);
        this.generateFilters(resp);
        this.report = resp;
        this.filterReport();
      };
      this.$store.dispatch("loadReportEventos", this.relatorio);
      this.$store.dispatch("setLastReport", this.relatorio);
    },
    generateFilters(report = []) {
      this.plates = [];
      this.colors = [];
      this.brands = [];
      this.sizes = [];
      // itera sobre os eventos para gerar os filtros
      report.forEach((element) => {
        // itera sobre as detecções
        if (
          Array.isArray(element.detections) &&
          element.detections.length > 0
        ) {
          element.detections.forEach((detection) => {
            // verifica se a placa já foi adicionada
            if (!this.plates.includes(detection.plate)) {
              this.plates.push(detection.plate);
            }
            // verifica se a cor já foi adicionada
            if (!this.colors.includes(detection.VehicleColor)) {
              this.colors.push(detection.VehicleColor);
            }
            // verifica se a marca já foi adicionada
            if (!this.brands.includes(detection.VehicleSign)) {
              this.brands.push(detection.VehicleSign);
            }
            // verifica se o tamanho já foi adicionado
            if (!this.sizes.includes(detection.VehicleSize)) {
              this.sizes.push(detection.VehicleSize);
            }
          });
        }
      });
    },
    // FSC3053
    filterReport() {
      let report = this.$store.getters.getReport;
      let filter = this.filter;
      let filtered = report.filter((item) => {
        let detections = item.detections;
        if (Array.isArray(detections) && detections.length > 0) {
          let plates = detections.map((d) => d.plate);
          let brands = detections.map((d) => d.VehicleSign);
          let sizes = detections.map((d) => d.VehicleSize);
          let colors = detections.map((d) => d.VehicleColor);
          let speeds = detections.map((d) => d.speed);
          let plate = filter.plates.length
            ? filter.plates.some((p) => plates.includes(p))
            : true;
          let brand = filter.brands.length
            ? filter.brands.some((b) => brands.includes(b))
            : true;
          let size = filter.sizes.length
            ? filter.sizes.some((s) => sizes.includes(s))
            : true;
          let color = filter.colors.length
            ? filter.colors.some((c) => colors.includes(c))
            : true;
          let speed =
            filter.speed > 0 ? filter.speed <= Math.min(...speeds) : true;
          return plate && brand && size && color && speed;
        }
        return false;
      });
      this.report = filtered;
    },
    loadPDF() {
      let filename = `${this.$store.getters.getCameraOnlyNameById(
        this.relatorio.cameraId
      )}`;
      this.$store.dispatch("setTitleDialog", "Gerando o Relátorio");
      this.$store.dispatch("setShowDialog", true);
      PDF.eventosCameraLPR(
       this.report,
        "Relatorios de eventos",
        true,
        null,
        filename
      )
        .then(() => {
          this.$store.dispatch("setMessageDialog", "Baixando arquivo");
          setTimeout(() => {
            this.$store.dispatch("setShowDialog", false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("setMessageDialog", "Erro ao gerar relatório");
          setTimeout(() => {
            this.$store.dispatch("setShowDialog", false);
          }, 1000);
        });
    },
    showImage(item) {
      this.dialog = true;
      setTimeout(() => {
        this.$store.commit("setEvento", item);
      }, 100);
    },
    search(value) {
      this.$store.dispatch("setFilterReport", value || "");
    },
    nextEvent(actual) {
      let idx = this.$store.getters.getReportFilter.findIndex(
        (evt) => evt.id == actual
      );
      if (idx + 1 < this.$store.getters.getReportFilter.length) {
        this.$store.dispatch(
          "setEvento",
          this.$store.getters.getReportFilter[idx + 1]
        );
      }
    },
    previousEvent(actual) {
      let idx = this.$store.getters.getReportFilter.findIndex(
        (evt) => evt.id == actual
      );
      if (idx - 1 >= 0) {
        this.$store.dispatch(
          "setEvento",
          this.$store.getters.getReportFilter[idx - 1]
        );
      }
    },
  },
  computed: {
    getMonitoramentos: {
      get() {
        return this.$store.getters.getMonitoramentos;
      },
      set() {},
    },
    getCamerasSelect: {
      get() {
        return this.$store.getters.getCamerasSelect;
      },
      set() {},
    },
  },
  mounted() {
    this.get();
    this.relatorio.cameraId = this.$store.getters.getLastReport.cameraId;
    this.relatorio.start = this.$store.getters.getLastReport.start;
    this.relatorio.end = this.$store.getters.getLastReport.end;
    this.$store.dispatch("setShowDialog", false);
  },
  beforeMount() {
    this.relatorio.cameraId = this.$store.getters.getLastReport.cameraId;
    this.relatorio.start = this.$store.getters.getLastReport.start;
    this.relatorio.end = this.$store.getters.getLastReport.end;
  },
};
</script>

<style>
</style>